import React from "react";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import KofiButton from "kofi-button"

const AboutPage = () => {
  return (
    <Layout>
      <div className="about-container cc-container">
        <Helmet title={`Support | ${config.siteTitle}`} />
        <SEO />
        <div className="cc-card">
          <div className="cc-padding cc-support cc-text">
            <p>
            Become a patron of the Inn and contribute via <a href="https://ko-fi.com/U7U3AL7KM">Ko-Fi</a>. It costs less than your favorite latte and keeps the show self sustaining and advertisement free. Monthly contributors also receive access to exclusive bonus content.
            </p>
            <p>
              <center>
                <KofiButton color="#7f905b" title="Tip Your Innkeeper" kofiID="U7U3AL7KM" />
              </center>
            </p>
              Thank you for supporting my work and independent writing!
            <p>
            </p>
            <br/>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;